@kkeyframes open {
  0% {
    background-position: 166% -25%, 220% -69%;
  }
  100% {
    background-position: 120% -5%, 200% -40%;
  }
}
form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ffield {
  margin: 5px;
  display: flex;
  flex-direction: column;
}
input[type="file"] {
  display: none;
}

.custom-file-upload {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  padding: 6px;
  cursor: pointer;
  background: linear-gradient(270deg, #3fa1a9, #79f1a4);
  margin-bottom: 25px;
  align-items: center;
}

.img-wrap {
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
  align-items: center;
}
.img-upload:before {
  content: "\f093";
  font-size: 90px;
  position: absolute;
  padding-top: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #63d3a6;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #fff;
}
.img-upload:hover:before {
  opacity: 1;
}
img {
  width: auto;
  height: 100%;
  align-items: center;
}

label {
  text-transform: uppercase;
  font-weight: 700;
  color: #676767;
}
